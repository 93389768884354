<template>
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
      <div v-else>
        <a href="javascript:void(0);" data-target_page="islamic-tunes" class="topup-section aos-init aos-animate tune-category" data-aos="fade">
          <img src="./../../../public/assets/uploads/images/islamic-tune.png" alt="undefined">
          <div class="category-overlay bg-blue">
            <h4>ইসলামিক</h4>
          </div>
        </a><a href="javascript:void(0);" data-target_page="modern-tunes" class="topup-section aos-init aos-animate tune-category" data-aos="fade">
        <img src="./../../../public/assets/uploads/images/adhunik.png" alt="undefined">
        <div class="category-overlay bg-orange">
          <h4>আধুনিক</h4>
        </div>
      </a><a href="javascript:void(0);" data-target_page="foke-tunes" class="topup-section aos-init aos-animate tune-category" data-aos="fade">
        <img src="./../../../public/assets/uploads/images/folk.png" alt="undefined">
        <div class="category-overlay bg-lightblue">
          <h4>ফোক</h4>
        </div>
      </a>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'

export default {
  name: 'caller-tune-page',
  data () {
    return {
      apiUrl: '/content/caller-tune-',
      dataDetails: {},
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      sessionId: sessionStorage.getItem('sessionId'),
      is_loading: true,
      subscriptionStatus: '',
      service: {},
      operatorId: localStorage.getItem('operatorId')
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      this.apiUrl += this.service
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
      // this.subscriptionStatus = this.dataDetails.is_subscribed
      // console.log(this.subscriptionStatus)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, null, { params: { languageId: this.languageId } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit () {
      sessionStorage.setItem('service', this.service)
      console.log('caller-tune-page-done')
      // this.$router.push(this.dataDetails.service.visit_url
    }
  }
}
</script>

<style scoped>

</style>
